'use strict';
jQuery(document).ready(function($){


    //Behandelingen slider
    var referentieSlides = $('.gallery a');
    var perViewLength = 1;
      var sliderType = 'slider';
    
      if(referentieSlides.length > 3){
        sliderType = 'carousel';
      
      } else if(referentieSlides.length < 4){
        $('.gallery .glide__arrows').hide();
        perViewLength =  referentieSlides.length;
      }
      var glide = new Glide('.gallery', {
        type: sliderType,
        perView: perViewLength,
        gap:20,
        peek: {
            before: 10,
            after: 200
        },
        breakpoints: {
            1000: {
              perView: 1,
              peek: {
                before: 10,
                after: 100
                }
            },
            600: {
                perView: 1,
                peek: {
                  before: 0,
                  after: 0
                  },
                gap:0
              }
         }
      });
      if(referentieSlides.length > 0){
        glide.mount();
      }


    //referenties slider
    var referentieSlides = $('.referenties .slide-item');
    var perViewLength = 2;
      var sliderType = 'slider';
    
      if(referentieSlides.length > 3){
        sliderType = 'carousel';
      
      } else if(referentieSlides.length < 4){
        $('.referenties .glide__arrows').hide();
        perViewLength =  referentieSlides.length;
      }
      var glide2 = new Glide('.referenties', {
        type: sliderType,
        perView: perViewLength,
        gap:20,
        peek: {
            before: 10,
            after: 100
        },
        breakpoints: {
            1000: {
              perView: 1,
              peek: {
                before: 10,
                after: 100
                }
            },
            600: {
                perView: 1,
                peek: {
                  before: 0,
                  after: 0
                  },
                gap:0
              }
         }
      });
      if(referentieSlides.length > 0){
        glide2.mount();
      }

     
    var nieuwsSlides = $('.nieuws .slide-item');
    var perViewLength = 3;
      var sliderType = 'slider';
    
      if(nieuwsSlides.length > 3){
        sliderType = 'carousel';
      
      } else if(nieuwsSlides.length < 4){
        $('.nieuws .glide__arrows').hide();
        perViewLength =  nieuwsSlides.length;
      }
      var glide3 = new Glide('.nieuws', {
        type: sliderType,
        perView: perViewLength,
        gap:20,
        peek: {
            before: 10,
            after: 100
        },
        breakpoints: {
            1000: {
              perView: 1,
              peek: {
                before: 10,
                after: 100
                }
            },
            600: {
                perView: 1,
                peek: {
                  before: 0,
                  after: 0
                  },
                gap:0
              }
         }
      });
      if(nieuwsSlides.length > 0){
        glide3.mount()
      }

      var documentSlides = $('.documenten .slide-item');
        var perViewLength = 2;
        var sliderType = 'slider';
      
        if(documentSlides.length > 2){
          sliderType = 'carousel';
        
        } else if(documentSlides.length < 3){
          $('.documenten .glide__arrows').hide();
          perViewLength =  documentSlides.length;
        }
        var glide4 = new Glide('.documenten', {
          type: sliderType,
          perView: perViewLength,
          gap:20,
          peek: {
              before: 10,
              after: 100
          },
          breakpoints: {
              1000: {
                perView: 1,
                peek: {
                  before: 10,
                  after: 100
                  }
              },
              600: {
                  perView: 1,
                  peek: {
                    before: 0,
                    after: 0
                    },
                  gap:0
                }
           }
        });
        if(documentSlides.length > 0){
          glide4.mount();
        }

    //document filter list
   $("#search-documents").on("submit", function(ev) {
      ev.preventDefault();
      var searchInput = $('#search-text').val();
      searchDocuments(searchInput);
   });
   $(".category-row .button1").on("click", function(ev) {
    
    var searchInput = $(this).data('value');
    searchDocuments(searchInput);
 });
//FILTER SEARCH RESULTS
function searchDocuments(searchInput){
  $("#document-list").find(".document").removeClass('inactive');
 
  var input = searchInput;
  var filter = input.toUpperCase();
  var documentList = $("#document-list");
  var documents = documentList.find(".document");
  var results = false;
  var index = [];


   for (var i = 0; i < documents.length; i++) {
    
     var txtValue = $(documents[i]).find('.title').val();
     var categoryValue = $(documents[i]).find('.category').text();
    
     var documentItem = documents[i];

      txtValue = documentItem.getElementsByClassName("title")[0].textContent ||  documentItem.getElementsByClassName("title")[0].innerText;
     
      
      if (txtValue.toUpperCase().indexOf(filter) > -1 || categoryValue.toUpperCase().indexOf(filter) > -1 ) {
        
        index.push($(documents[i]).data('index'));
       
        results = true;
       
        $(documentItem).addClass('active');
      } else {
       $(documentItem).addClass('inactive');
      }
      
   } 
   if(results){
    index =  index.sort();
    glide4.update({ startAt: index[0] })
    // $('#document-list > .active').each(function(){
    //   $(this).prependTo(this.parentNode);
    // });
    // var activeItems = $('#document-list .active');
    // var index = $(activeItems[0]).data('index');
    // console.log(index);
    
    $('#alert').hide();
  } else {
    $('#alert').show();
    
  }
}
  //REMOVE EMPTY DOCUMENT FILTER BUTTONS
  var filterArray = [];
  $('.merk.section-three.grid-container.fluid .slide-item .categories .category').each(function(item){
    
    filterArray.push($(this).text());
  });

  $('.merk.section-three.grid-container.fluid .intro .category-row .button1').each(function(){
    if(!(filterArray.includes($(this).text()))){
      $(this).hide();
    }
  });

  //SCROLL MENU LEFT
  var windowWidth = $(window).width();
 
  if(windowWidth < 1000){
   
    glide4.update();
  } else {
   var controller = new ScrollMagic.Controller();
   var height1 = $('.section-two').height();
   var height2 = $('.section-two .left-menu').height();
   var height3 = height1 * 0.90;
  
      // build scene
      var scene = new ScrollMagic.Scene({
            triggerElement: "#left-menu", 
            duration: height3,
            offset:250})
            .setPin("#left-menu")
            .addTo(controller);
      }
});
